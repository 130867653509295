import React from "react";

// @material-ui/core components
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";
import TablePagination from "@material-ui/core/TablePagination";
import Checkbox from "@material-ui/core/Checkbox";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

// core components
import UserHeader from "../../components/Headers/UserHeader.js";

import componentStyles from "../../assets/theme/views/admin/tables.js";
import { CardActionArea, IconButton, Typography } from "@material-ui/core";
import { useHistory } from "react-router";

const useStyles = makeStyles(componentStyles);

const Search = (props) => {
    const history = useHistory();
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(12);
    const [searchText, setSearchText] = React.useState("");
    const [loaded, setLoaded] = React.useState(false);

    const [date, setDate] = React.useState({
        startDate: `${new Date().getFullYear()}-${
            new Date().getMonth() + 1
        }-${new Date().getDate()}`,
        endDate: `${new Date().getFullYear()}-${
            new Date().getMonth() + 1
        }-${new Date().getDate()}`,
    });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [data, setData] = React.useState([]);
    const [list, setList] = React.useState([]);

    React.useEffect(() => {
        console.log(date);
        const requestOptions = {
            credentials: "include",
            mode: "cors",
            method: "POST",
            headers: { 
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("session")
            },
            body: JSON.stringify(date),
        };

        fetch(
            "https://www.reewards.club/api/v1/specfictaskanalytics/",
            requestOptions
        )
            .then((res) => res.json())
            .then((doc) => {
                if(doc.message === "Unauthorized") {
                    localStorage.removeItem("token");
                    localStorage.removeItem("session");
                    window.location.replace("/auth/login");    
                } 

                console.log(doc.data);
                if (doc.data) {
                    setList({ list: doc });
                } else {
                    alert("No value found");
                }
            })
            .catch((err) => alert(err));
    }, []);

    // const handleInputChange = (e) => {
    //     e.preventDefault();
    //     setData(e.target.value);
    // };

    const handleInputChange = (e) => {
        setDate({
            ...date,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = () => {
        console.log(date);
        const requestOptions = {
            credentials: "include",
            mode: "cors",
            method: "POST",
            headers: { 
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("session")
            },
            body: JSON.stringify(date),
        };

        fetch(
            "https://www.reewards.club/api/v1/specfictaskanalytics",
            requestOptions
        )
            .then((res) => res.json())
            .then((doc) => {
                if(doc.message === "Unauthorized") {
                    localStorage.removeItem("token");
                    localStorage.removeItem("session");
                    window.location.replace("/auth/login");    
                } 
                if (doc.data) {
                    setList({ list: doc });
                } else {
                    alert("Value not found");
                }
            })
            .catch((err) => {
                alert(err);
            });
    };

    const columns = [
        { id: "taskname", label: "Task Name" },
        { id: "totalclicks", label: "Clicks", align: "right" },
        { id: "totalconversion", label: "Conversion", align: "right" },
        { id: "totalcr", label: "CR", align: "right" },
        { id: "totalpayout", label: "Payout", align: "right" },
        { id: "totalrevenue", label: "Revenue", align: "right" },
        { id: "more", label: "More", align: "center" },
    ];

    // function createData(l) {
    //     const listedObj = Object.values(l);
    //     return Object.values(listedObj).map((row) =>
    //         Object.entries(row.data).map((key, val) => {
    //             return key[1];
    //         })
    //     );
    // }

    function createData(l) {
        const listedObj = Object.values(l);
        const rowData = Object.values(listedObj).map((row) =>
            Object.entries(row.data).map((key, val) => {
                return key[1];
            })
        );
        return rowData;
    }

    const rows = createData(list);
    // console.log("start");
    // rows.map((val) => console.log(val));
    // console.log("end");

    let rowslength;
    rows.forEach((val) => {
        rowslength = val.length;
    });

    React.useEffect(() => {
        if (list && list.list && list.list.data) {
            setLoaded(true);
        }
        if (loaded) {
            const arrays = Object.values(list.list.data);
            console.log(arrays);
            const arrLen = arrays.length;
            const latestTask = arrays[arrLen - 1];
            for (let i = 0; i < latestTask.length; i++) {
                latestTask[i].totalcr = parseFloat(latestTask[i].totalcr);
                latestTask[i].totalcr /= arrLen;
            }
            for (let i = 0; i < arrays.length - 1; i++) {
                for (let j = 0; j < arrays[i].length; j++) {
                    latestTask[j].totalclicks += arrays[i][j].totalclicks;
                    latestTask[j].totalconversion +=
                        arrays[i][j].totalconversion;
                    latestTask[j].totalcr +=
                        parseFloat(arrays[i][j].totalcr) / arrLen;
                    latestTask[j].totalpayout += arrays[i][j].totalpayout;
                    latestTask[j].totalrevenue += arrays[i][j].totalrevenue;
                }
            }
            console.log(latestTask);
            setData(latestTask);
        }
    }, [list, loaded]);

    return (
        <>
            <UserHeader />
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-24rem"
                classes={{ root: classes.containerRoot }}
            >
                <Card
                    classes={{ root: classes.cardRoot }}
                    style={{ backgroundColor: "transparent" }}
                >
                    <CardHeader
                        className={classes.cardHeader}
                        title="Filter"
                        titleTypographyProps={{
                            component: Box,
                            marginBottom: "0!important",
                            variant: "h3",
                        }}
                        style={{
                            marginBottom: "5rem",
                            backgroundColor: "white",
                        }}
                        action={
                            <Box
                                justifyContent="flex-end"
                                display="flex"
                                flexWrap="wrap"
                            >
                                <form
                                    className={classes.textfieldContainer}
                                    noValidate
                                >
                                    <TextField
                                        id="taskId"
                                        name="taskId"
                                        label="Search"
                                        type="text"
                                        variant="outlined"
                                        margin="10px"
                                        value={searchText}
                                        onChange={(e) =>
                                            setSearchText(e.target.value)
                                        }
                                        className={classes.textField}
                                    />
                                    <TextField
                                        id="startDate"
                                        name="startDate"
                                        label="Start Date"
                                        defaultValue="24-05-2021"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        type="date"
                                        variant="outlined"
                                        margin="10px"
                                        value={date.startDate}
                                        onChange={handleInputChange}
                                        className={classes.textField}
                                    />
                                    <TextField
                                        id="endDate"
                                        name="endDate"
                                        label="End Date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        type="date"
                                        variant="outlined"
                                        margin="10px"
                                        value={date.endDate}
                                        onChange={handleInputChange}
                                        className={classes.textField}
                                    />
                                    <Button
                                        variant="contained"
                                        color="info"
                                        margin="1rem!important"
                                        onClick={() => handleSubmit()}
                                        classes={classes.buttonRootUnselected}
                                    >
                                        Submit
                                    </Button>
                                </form>
                            </Box>
                        }
                    ></CardHeader>
                    <Grid container xs={12}>
                        <Grid
                            container
                            xs={12}
                            justifyContent="flex-end"
                            alignItems="flex-end"
                        ></Grid>
                    </Grid>

                    {/* Cards */}
                    <Grid container spacing={6} style={{ padding: "1rem" }}>
                        {/* {rows.map((val) => */}
                        {data
                            .filter(
                                (ele) =>
                                    ele.totalrevenue !== 0 &&
                                    ele.totalpayout !== 0
                            )
                            .filter((ele) =>
                                ele.taskname
                                    .toUpperCase()
                                    .startsWith(searchText.toUpperCase())
                            )
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            .map((row) => (
                                <Grid item xs={3} style={{ marginTop: "2rem" }}>
                                    <Paper
                                        elevation={5}
                                        style={{ padding: "2rem" }}
                                    >
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Typography variant="h3">
                                                    {row.taskname}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <img
                                                    // src={`https://picsum.photos/seed/${row.taskname}/150`}
                                                    src={
                                                        row.image ??
                                                        `https://picsum.photos/seed/${row.taskname}/120`
                                                    }
                                                    alt="appLogo"
                                                    style={{
                                                        width: "120px",
                                                        marginTop: "-5rem",
                                                        marginBottom: "2rem",
                                                        borderRadius: "100%",
                                                    }}
                                                />
                                            </Grid>

                                            <Grid
                                                item
                                                xs={6}
                                                style={{
                                                    marginTop: "0.5rem",
                                                }}
                                            >
                                                <Typography variant="h5">
                                                    Revenue
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                style={{
                                                    marginTop: "0.5rem",
                                                }}
                                            >
                                                ₹ {row.totalrevenue}
                                            </Grid>

                                            <Grid
                                                item
                                                xs={6}
                                                style={{
                                                    marginTop: "0.5rem",
                                                }}
                                            >
                                                <Typography variant="h5">
                                                    Payout
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                style={{
                                                    marginTop: "0.5rem",
                                                }}
                                            >
                                                ₹ {row.totalpayout}
                                            </Grid>

                                            <Grid
                                                item
                                                xs={6}
                                                style={{
                                                    marginTop: "0.5rem",
                                                }}
                                            >
                                                <Typography variant="h5">
                                                    Clicks
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                style={{
                                                    marginTop: "0.5rem",
                                                }}
                                            >
                                                {row.totalclicks}
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                style={{
                                                    marginTop: "0.5rem",
                                                }}
                                            >
                                                <Typography variant="h5">
                                                    Conversions
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                style={{
                                                    marginTop: "0.5rem",
                                                }}
                                            >
                                                {row.totalconversion}
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                style={{
                                                    marginTop: "0.5rem",
                                                }}
                                            >
                                                <Typography variant="h5">
                                                    CR
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                style={{
                                                    marginTop: "0.5rem",
                                                }}
                                            >
                                                {(row.totalconversion/row.totalclicks*100).toFixed(1)}%
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    marginTop: "2rem",
                                                }}
                                            >
                                                <Button
                                                    variant="contained"
                                                    onClick={() =>
                                                        console.log("Explore")
                                                    }
                                                >
                                                    Show all
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            ))}
                    </Grid>
                    {/* <TableContainer>
                        <Box
                            component={Table}
                            alignItems="center"
                            marginBottom="0!important"
                        >
                            <TableHead>
                                <TableRow style={{ paddingLeft: "2rem" }}>
                                    {columns.map((column, idx) => (
                                        <>
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{
                                                    minWidth: column.minWidth,
                                                }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        </>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((val) =>
                                    val
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((row) => (
                                            <>
                                                <TableRow
                                                    hover
                                                    key={data}
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    <TableCell
                                                        style={{
                                                            paddingLeft: "2rem",
                                                        }}
                                                    >
                                                        {row.taskname}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {row.totalclicks}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {row.totalconversion}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {row.totalcr}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {row.totalpayout}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {row.totalrevenue}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <IconButton
                                                            onClick={() =>
                                                                setCurrentTask(
                                                                    row
                                                                )
                                                            }
                                                        >
                                                            <MoreHorizIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        ))
                                )}
                            </TableBody>
                        </Box>
                    </TableContainer> */}
                    <Box
                        classes={{ root: classes.cardActionsRoot }}
                        component={CardActions}
                        justifyContent="flex-end"
                    >
                        {data.length ? (
                            <TablePagination
                                rowsPerPageOptions={false}
                                component="div"
                                count={
                                    data.filter(
                                        (ele) =>
                                            ele.totalrevenue !== 0 &&
                                            ele.totalpayout !== 0
                                    ).length
                                }
                                backgroundColor="primary"
                                variant="outlined"
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        ) : null}
                    </Box>
                </Card>
            </Container>
        </>
    );
};

export default Search;
