import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import SendToken from "./SendToken";

const auth = getAuth();

export default function firebaseAuth(email, password) {

    signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
        // Signed in 
        console.log(userCredential);
        const user = userCredential.user;
        localStorage.setItem("token", user.stsTokenManager.accessToken);
        SendToken();
    })
    .catch((error) => {
        alert("Something went wrong!");
    });
    
};