// core components
import Dashboard from "./views/admin/Dashboard.js";
import ReferAnalytics from "./views/admin/ReferAnalytics.js";
import Paytm from "./views/admin/Paytm.js";
import TaskAnalytics from "./views/admin/TaskAnalytics.js";
import Task from "./views/admin/Task.js";
import Task2 from "./views/admin/Task2.js";
import GiftCard from "./views/admin/GoogleGiftcard";
import SearchSharpIcon from "@material-ui/icons/SearchSharp";
import Person from "@material-ui/icons/Person";
import Tv from "@material-ui/icons/Tv";
import VpnKey from "@material-ui/icons/VpnKey";
import AccountBalanceSharpIcon from "@material-ui/icons/AccountBalanceSharp";
import TaskEvents from "views/admin/TaskEvents.js";
import Login from "views/auth/Login";
import RealtimeAnalytics from "views/admin/RealtimeAnalytics.js";
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import BarChartIcon from '@mui/icons-material/BarChart';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import LegendToggleOutlinedIcon from '@mui/icons-material/LegendToggleOutlined';

var routes = [
    {
        id: "dashboard",
        path: "/index",
        name: "Dashboard",
        icon: Tv,
        component: Dashboard,
        layout: "/admin",
        item: [],
    },
    {
        id: "redeem-history",
        path: "/index",
        name: "Redeem History",
        icon: AttachMoneyOutlinedIcon,
        component: null,
        layout: "/admin",
        item: [
            {
                id: "paytm",
                path: "/paytm",
                name: "PayTM",
                icon: Person,
                iconColor: "WarningLight",
                component: Paytm,
                layout: "/admin",
            },
            // {
            //     id: "google-giftcard",
            //     path: "/google-giftcard",
            //     name: "Google Giftcard",
            //     icon: VpnKey,
            //     iconColor: "Info",
            //     component: GiftCard,
            //     layout: "/admin",
            // },
            // {
            //     id: "freefire",
            //     path: "/freefire",
            //     name: "Freefire",
            //     icon: VpnKey,
            //     iconColor: "Info",
            //     component: Dashboard,
            //     layout: "/admin",
            // },
        ],
    },
    {
        id: "refer-details",
        path: "/refer-details",
        name: "Refer Report",
        icon: SearchSharpIcon,
        component: ReferAnalytics,
        layout: "/admin",
        item: [],
    },
    {
        id: "task",
        path: "/task",
        name: "Manage Task ",
        icon: LocalOfferIcon,
        component: Task,
        layout: "/admin",
        item: [],
    },
    // {
    //     id: "task2",
    //     path: "/challenges",
    //     name: "Challenges",
    //     icon: TaskAltIcon,
    //     // iconColor: "bgInfo",
    //     component: Task2,
    //     layout: "/admin",
    //     item: [],
    // },
    {
        id: "task-analytics",
        path: "/task-analytics",
        name: "Task Analytics",
        icon: BarChartIcon,
        component: TaskAnalytics,
        layout: "/admin",
        item: [],
    },
    {
        id: "task-events",
        path: "/task-events",
        name: "Task Events",
        icon: EventAvailableIcon,
        component: TaskEvents,
        layout: "/admin",
        item: [],
    },
    {
        id: "realtime-analytics",
        path: "/realtime-analytics",
        name: "Realtime Analytics",
        icon: LegendToggleOutlinedIcon,
        component: RealtimeAnalytics,
        layout: "/admin",
        item: [],
    },
    // {
    //     id: "register",
    //     path: "/register",
    //     name: "Register",
    //     icon: AccountBalanceSharpIcon,
    //     iconColor: "Info",
    //     component: Register,
    //     layout: "/auth",
    //     item: [],
    // },
    {
        id: "login",
        path: "/login",
        name: "",
        iconColor: "Info",
        component: Login,
        layout: "/auth",
        item: [],
    }
];
export default routes;

// {
//   id: 'redeem-history',
//   path: "/",
//   name: "Redeem History",
//   icon: AccountBalanceSharpIcon,
//   iconColor: "Error",
//   component: "",
//   layout: "/",
//   item:[
//     {
//       id: 'paytm',
//       path: "/paytm",
//       name: "PayTM",
//       icon: Person,
//       iconColor: "WarningLight",
//       component: Paytm,
//       layout: "/redeem-history",
//     },
//     {
//       id: 'login',
//       path: "/login",
//       name: "Google Giftcard",
//       icon: VpnKey,
//       iconColor: "Info",
//       component: Paytm,
//       layout: "/auth/index"
//     },
//     {
//       id: 'login',
//       path: "/login",
//       name: "Freefire",
//       icon: VpnKey,
//       iconColor: "Info",
//       component: Paytm,
//       layout: "/auth/index"
//     },
