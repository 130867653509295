import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// core components
import componentStyles from "../../assets/theme/components/card-stats.js";
import boxShadows from "../../assets/theme/box-shadow.js";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles(componentStyles);

function CardStats({
    subtitle,
    title,
    footer,
    icon,
    color,
    editable,
    updateFun,
    defaultVal,
}) {
    const classes = useStyles();
    const theme = useTheme();
    return (
        <>
            <Card classes={{ root: classes.cardRoot }} elevation={3}>
                <CardContent classes={{ root: classes.cardContentRoot }}>
                    <Grid
                        container
                        component={Box}
                        justifyContent="space-between"
                    >
                        <Grid item xs="auto">
                            <Box
                                component={Typography}
                                variant="h5"
                                color={theme.palette.gray[600] + "!important"}
                                marginBottom="0!important"
                                marginTop="0!important"
                                className={classes.textUppercase}
                            >
                                {subtitle}
                            </Box>
                            <Box
                                component={Typography}
                                variant="h2"
                                fontWeight="600!important"
                                marginBottom="0!important"
                                marginTop="0!important"
                                style={{ display: "flex" }}
                            >
                                {title}
                                {editable && (
                                    <IconButton
                                        onClick={() => {
                                            const res = prompt(
                                                "Enter new value",
                                                defaultVal
                                            );
                                            if (res) {
                                                return updateFun(res);
                                            } else return updateFun(defaultVal);
                                        }}
                                        style={{
                                            display: "flex",
                                            borderRadius: "100%",
                                            cursor: "pointer",
                                            marginLeft: "5px",
                                            marginBlock: "-5px",
                                            color: "#888",
                                        }}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            style={{ height: "15px" }}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                            />
                                        </svg>
                                    </IconButton>
                                )}
                            </Box>
                        </Grid>

                        <Grid item xs={"auto"}>
                            <Box
                                width="3rem"
                                height="3rem"
                                padding="12px"
                                textAlign="center"
                                display="inline-flex"
                                alignItems="center"
                                justifyContent="center"
                                borderRadius="50%"
                                boxShadow={boxShadows.boxShadow}
                                color={theme.palette.white.main}
                                className={classes[color]}
                            >
                                {icon && typeof icon === "object" ? (
                                    <Box
                                        component={icon}
                                        width="1.5rem!important"
                                        height="1.5rem!important"
                                    />
                                ) : null}
                                {icon && typeof icon === "string" ? (
                                    <Box
                                        component="i"
                                        fontSize="1.25rem"
                                        className={icon}
                                    />
                                ) : null}
                            </Box>
                        </Grid>
                    </Grid>
                    {footer ? (
                        <Box
                            component="p"
                            fontSize=".875rem"
                            color={theme.palette.gray[600]}
                            marginTop="1rem"
                            marginBottom="0"
                            display="flex"
                            alignItems="center"
                            flexWrap="wrap"
                        >
                            {footer}
                        </Box>
                    ) : null}
                </CardContent>
            </Card>
        </>
    );
}

CardStats.defaultProps = {
    color: "bgPrimaryLight",
};

CardStats.propTypes = {
    subtitle: PropTypes.string,
    title: PropTypes.string,
    footer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    icon: PropTypes.oneOfType([
        // i.e. an icon name from Nucleo Icons - e.g. ni ni-atom
        // // or an icon name from Font Awesome - e.g. fa fa-heart
        PropTypes.string,
        // i.e. a component from @material-ui/icons
        PropTypes.object,
    ]),
    color: PropTypes.oneOf([
        "bgPrimary",
        "bgPrimaryLight",
        "bgError",
        "bgErrorLight",
        "bgWarning",
        "bgWarningLight",
        "bgInfo",
        "bgInfoLight",
    ]),
};

export default CardStats;
