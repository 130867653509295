import axios from "axios";

export default function SendToken() {

    const data = {
        "idToken": localStorage.getItem("token")
    }

    axios
        .post("https://www.reewards.club/api/v1/auth/login", data)
        .then(response => {
            localStorage.setItem("session", response.data.session);
            window.location.replace('/admin/index');
        })
        .catch(err => {
            console.log(err);
        })

}