import React from "react";

// @material-ui/core components
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import TextField from "@material-ui/core/TextField";

// core components
import UserHeader from "../../components/Headers/UserHeader.js";

import componentStyles from "../../assets/theme/views/admin/tables.js";

const useStyles = makeStyles(componentStyles);

const TaskEvents = (props) => {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [taskEvents, setTaskEvents] = React.useState({});
    const [currentTask, setCurrentTask] = React.useState({});
    const [editingTaskId, setEdititngTaskId] = React.useState(-1);

    const baseEventUrl = [
        (userpayout, myrevenue, payout) =>
            `https://reewards.club/api/v1/register?sub_aff_id={sub_aff_id}&aff_sub1={aff_sub1}&userpayout=${userpayout}&myrevenue=${myrevenue}&payout=${payout}`,
        (userpayout, myrevenue, payout) =>
            `https://reewards.club/api/v1/deposite?sub_aff_id={sub_aff_id}&aff_sub1={aff_sub1}&userpayout=${userpayout}&myrevenue=${myrevenue}&payout=${payout}`,
        (userpayout, myrevenue, payout) =>
            `https://reewards.club/api/v1/gameplay?sub_aff_id={sub_aff_id}&aff_sub1={aff_sub1}&userpayout=${userpayout}&myrevenue=${myrevenue}&payout=${payout}`,
    ];

    // Searching
    const [searchText, setSearchText] = React.useState("");

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [removetasks, setRemovetasks] = React.useState([]);
    React.useEffect(() => {
        const requestOptions = {
            credentials: "include",
            mode: "cors",
            method: "POST",
            headers: { "Content-Type": "application/json",
            "Authorization": localStorage.getItem("session") },
            body: JSON.stringify({ taskid: null }),
        };

        fetch("https://reewards.club/api/v1/tasksfetch", requestOptions)
            .then((res) => res.json())
            .then((doc) => {
                if(doc.message === "Unauthorized") {
                    localStorage.removeItem("token");
                    localStorage.removeItem("session");
                    window.location.replace("/auth/login");    
                } 
                setRemovetasks({ removetasks: doc });
            })
            .catch((err) => alert(err));
    }, []);

    React.useEffect(() => {
        if (currentTask.events && removetasks && removetasks.removetasks) {
            let obj = { ...removetasks.removetasks.data };
            obj[currentTask.appId] = currentTask;

            // console.log(obj);
        }
    }, [currentTask, removetasks]);

    const columns1 = [
        { id: "appId", label: "App ID", align: "right" },
        { id: "appImageUrl", label: "Image", align: "center" },
        { id: "appName", label: "Task Name", align: "right" },
    ];

    function createData(l) {
        const listedObj = Object.values(l);
        return Object.values(listedObj).map((row) =>
            Object.entries(row.data).map((key, val) => {
                return key[1];
            })
        );
    }

    const rows1 = createData(removetasks);
    let rowslength1;
    rows1.forEach((val) => {
        rowslength1 = val.length;
    });

    const handleAddEvent = () => {
        let evts = { ...taskEvents };
        let idx = Object.entries(evts).length;
        while (evts[idx] !== undefined) idx++;
        evts[idx] = {
            title: "Event Title",
            descriptions: "Event description",
            coins: "",
         
          
        };
        setTaskEvents(evts);
    };

    const handleSave = () => {
        let ct = { ...currentTask };
        ct.events = { ...taskEvents };
        // setCurrentTask(ct);
        console.log({ appId: ct.appId, ...ct.events });
        const requestOptions = {
            credentials: "include",
            mode: "cors",
            method: "POST",
            headers: { "Content-Type": "application/json",
            "Authorization": localStorage.getItem("session") },
            body: JSON.stringify({ appId: ct.appId, ...ct.events }),
        };

        fetch("https://reewards.club/api/v1/taskseventadd/", requestOptions)
            .then((response) => {
                if(response.message === "Unauthorized") {
                    localStorage.removeItem("token");
                    localStorage.removeItem("session");
                    window.location.replace("/auth/login");    
                }     
                console.log("Done")
            })
            .catch((err) => console.error(err));
    };

    return (
        <>
            <UserHeader />
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-24rem"
                classes={{ root: classes.containerRoot }}
            >
                <Card classes={{ root: classes.cardRoot }}>
                    <CardHeader
                        className={classes.cardHeader}
                        title={"Task Events"}
                        titleTypographyProps={{
                            component: Box,
                            marginBottom: "0!important",
                            variant: "h3",
                        }}
                        action={
                            <div>
                                <TextField
                                    id="searchField"
                                    name="searchField"
                                    value={searchText}
                                    variant="outlined"
                                    placeholder="Search"
                                    onChange={(e) =>
                                        setSearchText(e.target.value)
                                    }
                                    style={{ marginRight: "10px" }}
                                />
                            </div>
                        }
                    ></CardHeader>
                    <Box style={{ display: "flex" }}>
                        <TableContainer style={{ flex: "1" }}>
                            <Box
                                component={Table}
                                alignItems="center"
                                marginBottom="0!important"
                                size="medium"
                            >
                                <TableHead>
                                    <TableRow>
                                        {columns1.map((column) => (
                                            <>
                                                <TableCell
                                                    key={column.id}
                                                    align="center" // {column.align}
                                                    style={{
                                                        minWidth:
                                                            column.minWidth,
                                                    }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            </>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows1.map((val) =>
                                        val
                                            .filter((ele) =>
                                                ele.appName
                                                    .toUpperCase()
                                                    .startsWith(
                                                        searchText.toUpperCase()
                                                    )
                                            )
                                            .slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage
                                            )
                                            .map((row) => (
                                                <>
                                                    <TableRow
                                                        hover
                                                        key={row.appId}
                                                        onClick={() => {
                                                            if (row.events) {
                                                                delete row
                                                                    .events
                                                                    .appId;
                                                            }
                                                            setTaskEvents(
                                                                row.events ?? {}
                                                            );
                                                            return setCurrentTask(
                                                                row
                                                            );
                                                        }}
                                                    >
                                                        <TableCell align="center">
                                                            {row.appId}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Tooltip
                                                                title={
                                                                    row.eventdecription ===
                                                                        undefined &&
                                                                    row.eventdecription2 ===
                                                                        undefined
                                                                        ? ""
                                                                        : row.eventdecription +
                                                                          " " +
                                                                          row.eventdecription2
                                                                }
                                                            >
                                                                <img
                                                                    src={
                                                                        row.appImageUrl
                                                                    }
                                                                    alt="icon"
                                                                    width="30rem"
                                                                />
                                                            </Tooltip>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {row.appName}
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            ))
                                    )}
                                </TableBody>
                            </Box>
                        </TableContainer>

                        {/* Events Card */}
                        <Card elevation={1} style={{ flex: "2" }}>
                            <Box
                                style={{
                                    width: "100%",
                                    padding: "1rem",
                                }}
                            >
                                {!currentTask.appName && (
                                    <Box style={{ paddingBlock: "1rem" }}>
                                        Select a task from list.
                                    </Box>
                                )}
                                {currentTask.appName && (
                                    <Box
                                        style={{
                                            display: "flex",
                                            paddingInline: "1rem",
                                            alignItems: "center",
                                        }}
                                    >
                                        <img
                                            src={currentTask.appImageUrl}
                                            alt="icon"
                                            style={{ height: "3rem" }}
                                        />
                                        <span style={{ marginLeft: "1rem" }}>
                                            <Typography variant="h3">
                                                {currentTask.appName}
                                            </Typography>
                                        </span>
                                    </Box>
                                )}
                            </Box>
                            <Box
                                style={{
                                    width: "100%",
                                    padding: "1rem",
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography variant="h2">Events</Typography>
                                <Box>
                                    <Button
                                        variant="contained"
                                        onClick={handleAddEvent}
                                        disabled={!currentTask.appName}
                                    >
                                        Add Event
                                    </Button>
                                    &nbsp;&nbsp;
                                    <Button
                                        variant="contained"
                                        onClick={handleSave}
                                        disabled={!currentTask.appName}
                                    >
                                        Save events
                                    </Button>
                                </Box>
                            </Box>
                            {currentTask.appName &&
                                !Object.entries(taskEvents).length && (
                                    <div style={{ margin: "1rem" }}>
                                        No events for this task exist yet.
                                    </div>
                                )}
                            {Object.entries(taskEvents).map(([k, v]) => (
                                <Card
                                    key={k}
                                    elevation={0}
                                    style={{
                                        margin: "0.5rem 1rem",
                                        padding: "0.5rem",
                                        minHeight: "5rem",
                                        border: "1px solid #ddd",
                                    }}
                                >
                                    <Grid
                                        container
                                        spacing={1}
                                        alignItems="center"
                                    >
                                        <Grid item xs={6}>
                                            {editingTaskId === k ? (
                                                <TextField
                                                    variant="outlined"
                                                    label="Title"
                                                    value={v.title}
                                                    onChange={(e) => {
                                                        let evts = {
                                                            ...taskEvents,
                                                        };
                                                        evts[k].title =
                                                            e.target.value;
                                                        return setTaskEvents(
                                                            evts
                                                        );
                                                    }}
                                                />
                                            ) : (
                                                <Typography variant="h3">
                                                    {v.title}
                                                </Typography>
                                            )}
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            style={{
                                                display: "flex",
                                                justifyContent: "end",
                                                paddingRight: "1rem",
                                            }}
                                        >
                                            <Button
                                                variant={
                                                    editingTaskId === k
                                                        ? "contained"
                                                        : "outlined"
                                                }
                                                onClick={() =>
                                                    setEdititngTaskId((prev) =>
                                                        prev === k ? -1 : k
                                                    )
                                                }
                                            >
                                                {editingTaskId === k
                                                    ? "Save"
                                                    : "Edit"}
                                            </Button>
                                            &nbsp;&nbsp;
                                            <Button
                                                variant="outlined"
                                                onClick={() => {
                                                    let evts = {
                                                        ...taskEvents,
                                                    };
                                                    delete evts[k];
                                                    setEdititngTaskId(-1);
                                                    return setTaskEvents(evts);
                                                }}
                                            >
                                                Delete
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {editingTaskId === k ? (
                                                <TextField
                                                    variant="outlined"
                                                    label="Description"
                                                    style={{ width: "99%" }}
                                                    value={v.descriptions}
                                                    onChange={(e) => {
                                                        let evts = {
                                                            ...taskEvents,
                                                        };
                                                        evts[k].descriptions =
                                                            e.target.value;
                                                        return setTaskEvents(
                                                            evts
                                                        );
                                                    }}
                                                />
                                            ) : (
                                                <span>{v.descriptions}</span>
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {editingTaskId === k ? (
                                                <TextField
                                                    variant="outlined"
                                                    label="Coins"
                                                    style={{ width: "99%" }}
                                                    value={v.coins}
                                                    onChange={(e) => {
                                                        let evts = {
                                                            ...taskEvents,
                                                        };
                                                        evts[k].coins =
                                                            e.target.value;
                                                        return setTaskEvents(
                                                            evts
                                                        );
                                                    }}
                                                />
                                            ) : (
                                                <span>Coins:{v.coins}</span>
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {editingTaskId === k ? (
                                                <TextField
                                                    variant="outlined"
                                                    label="Revenue"
                                                    style={{ width: "99%" }}
                                                    value={v.revenue}
                                                    onChange={(e) => {
                                                        let evts = {
                                                            ...taskEvents,
                                                        };
                                                        evts[k].revenue =
                                                            e.target.value;
                                                        return setTaskEvents(
                                                            evts
                                                        );
                                                    }}
                                                />
                                            ) : (
                                                <span>Revenue:{v.revenue}</span>
                                            )}
                                        </Grid>
                                       
                                        <Grid item xs={12}>
                                            {editingTaskId === k ? (
                                                <TextField
                                                    variant="outlined"
                                                    label="Payout"
                                                    style={{ width: "100%" }}
                                                    value={v.payout}
                                                    onChange={(e) => {
                                                        let evts = {
                                                            ...taskEvents,
                                                        };
                                                        evts[k].payout =
                                                            e.target.value;
                                                        return setTaskEvents(
                                                            evts
                                                        );
                                                    }}
                                                />
                                            ) : (
                                                <span>Payout: {v.payout}</span>
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <strong>
                                                URL:{" "}
                                                {baseEventUrl[k](
                                                    v.coins,
                                                    v.revenue,
                                                    v.payout
                                                )}{" "}
                                            </strong>
                                        </Grid>
                                    </Grid>
                                </Card>
                            ))}
                        </Card>
                    </Box>
                    <Box
                        classes={{ root: classes.cardActionsRoot }}
                        component={CardActions}
                        justifyContent="flex-end"
                    >
                        {rows1.length && (
                            <TablePagination
                                rowsPerPageOptions={false}
                                component="div"
                                count={rowslength1}
                                backgroundColor="primary"
                                variant="outlined"
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        )}
                    </Box>
                </Card>
            </Container>
        </>
    );
};

export default TaskEvents;