import React, { useEffect, useState } from "react";

// @material-ui/core components
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import Delete from "@material-ui/icons/Delete";
import EditSharpIcon from "@material-ui/icons/EditSharp";
import { red, yellow, blue } from "@material-ui/core/colors";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";

// core components
import UserHeader from "../../components/Headers/UserHeader.js";

import componentStyles from "../../assets/theme/views/admin/tables.js";

const useStyles = makeStyles(componentStyles);

const Task = (props) => {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [page2, setPage2] = React.useState(0);
    const [page3, setPage3] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    // This is for editing tasks
    const [isEditingTask, setIsEditingTask] = useState(false);
    const [taskToEdit, setTaskToEdit] = useState({});

    // For new task
    const [isCreatingNewTask, setIsCreatingNewTask] = useState(false);

    // Searching
    const [searchText, setSearchText] = useState("");

    const [events, setEvents] = useState({});

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangePage2 = (event, newPage) => {
        setPage2(newPage);
    };

    const handleChangePage3 = (event, newPage) => {
        setPage3(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // const [data, setData] = React.useState();
    const [removetasks, setRemovetasks] = React.useState([]);
    React.useEffect(() => {
        const requestOptions = {
            credentials: "include",
            mode: "cors",
            method: "POST",
            headers: { 
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("session")
            },
            body: JSON.stringify({ taskid: null }),
        };

        fetch("https://www.reewards.club/api/v1/tasksfetch", requestOptions)
            .then((res) => res.json())
            .then((doc) => {
                if(doc.message === "Unauthorized") {
                    localStorage.removeItem("token");
                    localStorage.removeItem("session");
                    window.location.replace("/auth/login");    
                } 
                setRemovetasks({ removetasks: doc });
            })
            .catch((err) => alert(err));
    }, []);

    const [tasks, setTasks] = React.useState([]);
    React.useEffect(() => {
        const requestOptions = {
            credentials: "include",
            mode: "cors",
            method: "POST",
            headers: { 
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("session")
            },
            body: JSON.stringify({ taskid: null }),
        };

        fetch("https://www.reewards.club/api/v1/removetasksfetch", requestOptions)
            .then((res) => res.json())
            .then((doc) => {
                if(doc.message === "Unauthorized") {
                    localStorage.removeItem("token");
                    localStorage.removeItem("session");
                    window.location.replace("/auth/login");    
                } 
                setTasks({ tasks: doc });
            })
            .catch((err) => alert(err));
    }, []);

    const [taskscaps, setTaskscaps] = React.useState([]);
    React.useEffect(() => {
        const requestOptions = {
            credentials: "include",
            mode: "cors",
            method: "POST",
            headers: { 
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("session")
            },
            body: JSON.stringify({ taskid: null }),
        };

        fetch("https://www.reewards.club/api/v1/capstaskfetch", requestOptions)
            .then((res) => res.json())
            .then((doc) => {
                if(doc.message === "Unauthorized") {
                    localStorage.removeItem("token");
                    localStorage.removeItem("session");
                    window.location.replace("/auth/login");    
                } 
                if(doc.data !=null){
                    setTaskscaps({ taskscaps: doc });
                   
                }
               
            })
            .catch((err) => alert(err));
            
    }, []);


    const handleDelete = (dataObj) => {
        const response = window.confirm("Are you sure you want to delete ?");
        if (response) {
            console.log("Request sent");
            const requestOptions = {
                credentials: "include",
                mode: "cors",
                method: "POST",
                headers: { 
                    "Content-Type": "application/json", 
                    "Authorization": localStorage.getItem("session")
                },
                body: JSON.stringify(dataObj),
            };
            fetch("https://www.reewards.club/api/v1/tasksremove/", requestOptions)
                .then((res) => res.json())
                .then((doc) => {
                    if(doc.message === "Unauthorized") {
                        localStorage.removeItem("token");
                        localStorage.removeItem("session");
                        window.location.replace("/auth/login");    
                    } 
                    setRemovetasks({ list: doc })
                })
                .catch((err) => {
                    alert(err);
                })
                .finally((_) => window.location.reload());
        }
    };

    const handleDelete2 = (dataObj) => {
        console.log(dataObj);
        const response = window.confirm("Are you sure you want to delete ?");
        if (response) {
            const requestOptions = {
                credentials: "include",
                mode: "cors",
                method: "POST",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("session")
                },
                body: JSON.stringify(dataObj),
            };
            fetch("https://www.reewards.club/api/v1/tasksrelive/", requestOptions)
                .then((res) => res.json())

                .then((doc) => {
                    if(doc.message === "Unauthorized") {
                        localStorage.removeItem("token");
                        localStorage.removeItem("session");
                        window.location.replace("/auth/login");    
                    } 
                    setRemovetasks({ list: doc })
                })
                .catch((err) => {
                    alert(err);
                })
                .finally((_) => window.location.reload());
        }
    };

    const handleDelete3 = (dataObj) => {
        console.log(dataObj);
        const response = window.confirm("Are you sure you want to delete ?");
        if (response) {
            const requestOptions = {
                credentials: "include",
                mode: "cors",
                method: "POST",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("session")
                },
                body: JSON.stringify(dataObj),
            };
            fetch("https://www.reewards.club/api/v1/taskscapsrelive/", requestOptions)
                .then((res) => res.json())

                .then((doc) => {
                    if(doc.message === "Unauthorized") {
                        localStorage.removeItem("token");
                        localStorage.removeItem("session");
                        window.location.replace("/auth/login");    
                    } 
                    setTaskscaps({ list: doc })
                })
                .catch((err) => {
                    alert(err);
                });
               
        }
    };


    const columns1 = [
        { id: "appId", label: "App ID", align: "right" },
        { id: "appImageUrl", label: "Image", align: "center" },
        { id: "appName", label: "Task Name", align: "right" },
        { id: "appRewardAmount", label: "Reward Amount", align: "right" },
        { id: "payout", label: "Payout", align: "right" },
        { id: "revenue", label: "Revenue", align: "right" },
        { id: "totalcap", label: "Total Caps", align: "right" },
        { id: "completecaps", label: "Complete Caps", align: "right" },
        { id: "del", label: "Delete", align: "right" },
        { id: "edit", label: "Edit", align: "right" },
    ];

    const columns2 = [
        { id: "appId", label: "App ID", align: "center" },
        { id: "appImageUrl", label: "Image", align: "center" },
        { id: "appName", label: "Task Name", align: "center" },
        { id: "appRewardAmount", label: "Reward Amount", align: "center" },
        { id: "payout", label: "Payout", align: "center" },
        { id: "revenue", label: "Revenue", align: "center" },
        { id: "totalcap", label: "Total Caps", align: "right" },
        { id: "completecaps", label: "Complete Caps", align: "center" },
        { id: "del", label: "Delete", align: "center" },
    ];


    const columns3 = [
        { id: "appId", label: "App ID", align: "center" },
        { id: "appImageUrl", label: "Image", align: "center" },
        { id: "appName", label: "Task Name", align: "center" },
        { id: "appRewardAmount", label: "Reward Amount", align: "center" },
        { id: "payout", label: "Payout", align: "center" },
        { id: "revenue", label: "Revenue", align: "center" },
        { id: "totalcap", label: "Total Caps", align: "right" },
        { id: "completecaps", label: "Complete Caps", align: "center" },
        { id: "del", label: "Delete", align: "center" },
    ];

    const [newTaskTemplate, setNewTaskTemplate] = useState({
        appDescription: "",
        appId: 0,
        appImageUrl: "",
        appName: "",
        appPackageName: "",
        appRewardAmount: "",
        appUrl: "",
        link:"",
        caps: "",
        completecaps: "",
        eventdecription: "",
        eventdecription2: "",
        payout: "",
        revenue: "",
        totalcap: "",
        totalevents: "",
        cr: "0",
    });

    React.useEffect(() => {
        // fetch data and update appId for newTaskTemplate

        fetch("https://www.reewards.club/api/v1/tasksid/", {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        })
            .then((res) => res.json())
            // .then((res) => console.log(res.data))
            .then((res) =>
                setNewTaskTemplate({ ...newTaskTemplate, appId: res.data })
            )
            .catch((err) => console.error(err));
    }, []);

    function createData(l) {
        const listedObj = Object.values(l);
        return Object.values(listedObj).map((row) =>
            Object.entries(row.data).map((key, val) => {
                return key[1];
             
            })
        );
    }

    const rows1 = createData(removetasks);
    let rowslength1;
    rows1.forEach((val) => {
        rowslength1 = val.length;
    });

    const rows2 = createData(tasks);
    let rowslength2;
    rows2.forEach((val) => {
        rowslength2 = val.length;
    });


   
    const rows3 = createData(taskscaps);
    let rowslength3;
  
        rows3.forEach((val) => {
            rowslength3 = val.length;
        });
   
   

    const handleTaskUpdate = (e) => {
        // console.log(taskToEdit);
        const requestOptions = {
            credentials: "include",
            mode: "cors",
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(taskToEdit),
        };
        e.preventDefault();
        setIsEditingTask(false);
        setIsCreatingNewTask(false);
        alert("Page will reload in 5 seconds.");
        setTimeout(() => window.location.reload(), 5000);
        if (isCreatingNewTask) {
            fetch("https://www.reewards.club/api/v1/tasksadd/", requestOptions)
                .then((_) => console.log("newTaskCreation request"))
                .catch((err) => console.error(err));
        } else {
            fetch("https://www.reewards.club/api/v1/tasksedit/", requestOptions)
                .then(() => console.log("Updated"))
                .catch((err) => console.error(err));
        }
    };

    return (
        <>
            <UserHeader />
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-24rem"
                classes={{ root: classes.containerRoot }}
            >
                <Card classes={{ root: classes.cardRoot }}>
                    <CardHeader
                        className={classes.cardHeader}
                        title={
                            isEditingTask ? "Edit Tasks" : "Live Task"
                        }
                        titleTypographyProps={{
                            component: Box,
                            marginBottom: "0!important",
                            variant: "h3",
                        }}
                        action={
                            <div>
                                <TextField
                                    id="searchField"
                                    name="searchField"
                                    value={searchText}
                                    variant="outlined"
                                    placeholder="Search"
                                    onChange={(e) =>
                                        setSearchText(e.target.value)
                                    }
                                    style={{ marginRight: "10px" }}
                                />
                                <Button
                                    variant="contained"
                                    disabled={isEditingTask}
                                    onClick={function () {
                                        setIsEditingTask(true);
                                        setIsCreatingNewTask(true);
                                        console.log(newTaskTemplate);
                                        setTaskToEdit({ ...newTaskTemplate });
                                    }}
                                >
                                    Add
                                </Button>
                            </div>
                        }
                    ></CardHeader>
                    {isEditingTask ? (
                        <Box padding="2rem">
                            <form>
                                <Grid container spacing={2}>
                                    {Object.entries(taskToEdit).map(
                                        (key, value) => (
                                            <Grid item key={key[0]} xs={6}>
                                                <TextField
                                                    label={key[0]}
                                                    value={key[1]}
                                                    onChange={(e) => {
                                                        let taskCopy = {
                                                            ...taskToEdit,
                                                        };
                                                        taskCopy[key[0]] =
                                                            e.target.value;
                                                        return setTaskToEdit(
                                                            taskCopy
                                                        );
                                                    }}
                                                    variant="outlined"
                                                    className={
                                                        classes.TextField
                                                    }
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                />
                                            </Grid>
                                        )
                                    )}
                                </Grid>
                            </form>
                            <br />
                            <Box
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    onClick={() => setIsEditingTask(false)}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={handleTaskUpdate}
                                    style={{ marginLeft: "1rem" }}
                                >
                                    Submit
                                </Button>
                            </Box>
                        </Box>
                    ) : (
                        <TableContainer>
                            <Box
                                component={Table}
                                alignItems="center"
                                marginBottom="0!important"
                                size="medium"
                            >
                                <TableHead>
                                    <TableRow>
                                        {columns1.map((column) => (
                                            <>
                                                <TableCell
                                                    key={column.id}
                                                    align="center" // {column.align}
                                                    style={{
                                                        minWidth:
                                                            column.minWidth,
                                                    }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            </>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows1.map((val) =>
                                        val
                                            .filter((ele) =>
                                                ele.appName
                                                    .toUpperCase()
                                                    .startsWith(
                                                        searchText.toUpperCase()
                                                    )
                                            )
                                            .slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage
                                            )
                                            .map((row) => (
                                                <>
                                                    <TableRow
                                                        hover
                                                        key={row.appId}
                                                    >
                                                        <TableCell align="center">
                                                            {row.appId}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Tooltip
                                                                title={
                                                                    row.eventdecription ===
                                                                        undefined &&
                                                                    row.eventdecription2 ===
                                                                        undefined
                                                                        ? ""
                                                                        : row.eventdecription +
                                                                          " " +
                                                                          row.eventdecription2
                                                                }
                                                            >
                                                                <img
                                                                    src={
                                                                        row.appImageUrl
                                                                    }
                                                                    width="30rem"
                                                                />
                                                            </Tooltip>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {row.appName}
                                                        </TableCell>

                                                        <TableCell align="center">
                                                            {
                                                                row.appRewardAmount
                                                            }
                                                        </TableCell>

                                                        <TableCell align="center">
                                                            ₹ {row.payout}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            ₹ {row.revenue}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {row.totalcap}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {row.completecaps}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <IconButton
                                                                color={red[500]}
                                                                onClick={() => {
                                                                    return handleDelete(
                                                                        row
                                                                    );
                                                                }}
                                                            >
                                                                <Delete />
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <IconButton
                                                                color={
                                                                    yellow[500]
                                                                }
                                                                onClick={() => {
                                                                    setIsEditingTask(
                                                                        true
                                                                    );
                                                                    setTaskToEdit(
                                                                        row
                                                                    );
                                                                }}
                                                            >
                                                                <EditSharpIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            ))
                                    )}
                                </TableBody>
                            </Box>
                        </TableContainer>
                    )}
                    {!isEditingTask && (
                        <Box
                            classes={{ root: classes.cardActionsRoot }}
                            component={CardActions}
                            justifyContent="flex-end"
                        >
                            {rows1.length ? (
                                <TablePagination
                                    rowsPerPageOptions={false}
                                    component="div"
                                    count={rowslength1}
                                    backgroundColor="primary"
                                    variant="outlined"
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={
                                        handleChangeRowsPerPage
                                    }
                                />
                            ) : null}
                        </Box>
                    )}
                </Card>
            </Container>

            <Container
                maxWidth={false}
                component={Box}
                marginTop="2rem"
                classes={{ root: classes.containerRoot }}
            >
                <Card classes={{ root: classes.cardRoot }}>
                    <CardHeader
                        className={classes.cardHeader}
                        title="Remove Tasks"
                        titleTypographyProps={{
                            component: Box,
                            marginBottom: "0!important",
                            variant: "h3",
                        }}
                    ></CardHeader>
                    <TableContainer>
                        <Box
                            component={Table}
                            alignItems="center"
                            marginBottom="0!important"
                            size="medium"
                        >
                            <TableHead>
                                <TableRow>
                                    {columns2.map((column) => (
                                        <>
                                            <TableCell
                                                key={column.id}
                                                align="center"
                                                style={{
                                                    minWidth: column.minWidth,
                                                }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        </>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows2.map((val) =>
                                    val
                                        .slice(
                                            page2 * rowsPerPage,
                                            page2 * rowsPerPage + rowsPerPage
                                        )
                                        .map((row) => (
                                            <>
                                                <TableRow hover key={row.appId}>
                                                    <TableCell align="center">
                                                        {row.appId}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Tooltip
                                                            title={
                                                                row.eventdecription ===
                                                                    undefined &&
                                                                row.eventdecription2 ===
                                                                    undefined
                                                                    ? ""
                                                                    : row.eventdecription +
                                                                      " " +
                                                                      row.eventdecription2
                                                            }
                                                        >
                                                            <img
                                                                src={
                                                                    row.appImageUrl
                                                                }
                                                                width="30rem"
                                                            />
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.appName}
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        {row.appRewardAmount}
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        {row.payout}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.revenue}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.totalcap}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.completecaps}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <IconButton
                                                            onClick={() =>
                                                                handleDelete2(
                                                                    row
                                                                )
                                                            }
                                                            color={red[500]}
                                                        >
                                                            <Delete />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        ))
                                )}
                            </TableBody>
                        </Box>
                    </TableContainer>
                    <Box
                        classes={{ root: classes.cardActionsRoot }}
                        component={CardActions}
                        justifyContent="flex-end"
                    >
                        {rows2.length ? (
                            <TablePagination
                                rowsPerPageOptions={false}
                                component="div"
                                count={rowslength2}
                                backgroundColor="primary"
                                variant="outlined"
                                rowsPerPage={rowsPerPage}
                                page={page2}
                                onPageChange={handleChangePage2}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        ) : null}
                    </Box>
                </Card>
            </Container>


            <Container
                maxWidth={false}
                component={Box}
                marginTop="2rem"
                classes={{ root: classes.containerRoot }}
            >
                <Card classes={{ root: classes.cardRoot }}>
                    <CardHeader
                        className={classes.cardHeader}
                        title="Pending Tasks"
                        titleTypographyProps={{
                            component: Box,
                            marginBottom: "0!important",
                            variant: "h3",
                        }}
                    ></CardHeader>
                    <TableContainer>
                        <Box
                            component={Table}
                            alignItems="center"
                            marginBottom="0!important"
                            size="medium"
                        >
                            <TableHead>
                                <TableRow>
                                    {columns3.map((column) => (
                                        <>
                                            <TableCell
                                                key={column.id}
                                                align="center"
                                                style={{
                                                    minWidth: column.minWidth,
                                                }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        </>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows3.map((val) =>
                                    val
                                        .slice(
                                            page3 * rowsPerPage,
                                            page3 * rowsPerPage + rowsPerPage
                                        )
                                        .map((row) => (
                                            <>
                                                <TableRow hover key={row.appId}>
                                                    <TableCell align="center">
                                                        {row.appId}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Tooltip
                                                            title={
                                                                row.eventdecription ===
                                                                    undefined &&
                                                                row.eventdecription2 ===
                                                                    undefined
                                                                    ? ""
                                                                    : row.eventdecription +
                                                                      " " +
                                                                      row.eventdecription2
                                                            }
                                                        >
                                                            <img
                                                                src={
                                                                    row.appImageUrl
                                                                }
                                                                width="30rem"
                                                            />
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.appName}
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        {row.appRewardAmount}
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        {row.payout}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.revenue}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.totalcap}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.completecaps}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <IconButton
                                                            onClick={() =>
                                                                handleDelete3(
                                                                    row
                                                                )
                                                            }
                                                            color={red[500]}
                                                        >
                                                            <Delete />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        ))
                                )}
                            </TableBody>
                        </Box>
                    </TableContainer>
                    <Box
                        classes={{ root: classes.cardActionsRoot }}
                        component={CardActions}
                        justifyContent="flex-end"
                    >
                        {rows3.length ? (
                            <TablePagination
                                rowsPerPageOptions={false}
                                component="div"
                                count={rowslength3}
                                backgroundColor="primary"
                                variant="outlined"
                                rowsPerPage={rowsPerPage}
                                page={page3}
                                onPageChange={handleChangePage3}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        ) : null}
                    </Box>
                </Card>
            </Container>

        </>
    );
};

export default Task;
