import React from "react";

// @material-ui/core components
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";
import TablePagination from "@material-ui/core/TablePagination";
import Checkbox from "@material-ui/core/Checkbox";

// core components
import UserHeader from "../../components/Headers/UserHeader.js";

import componentStyles from "../../assets/theme/views/admin/tables.js";

const useStyles = makeStyles(componentStyles);

let arr = [];

const Search = (props) => {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [data, setData] = React.useState({
        date: null,
        refercode: null,
        email: null,
        amount: null,
        totalotheroffer: null,
        totalredeem: null,
        totalsurvey: null,
        totaltaskoffer: null,
        uid: null,
    });
    const [list, setList] = React.useState([]);

    const [radio, setRadio] = React.useState("none");

    React.useEffect(() => {
        if (radio === "none") {
            const requestOptions = {
                credentials: "include",
                mode: "cors",
                method: "POST",
                headers: { 
                    "Content-Type": "application/json", 
                    "Authorization": localStorage.getItem("session")            
                },
                body: null,
            };

            fetch(
                "https://www.reewards.club/api/v1/giftcardredeemfetch",
                requestOptions
            )
                .then((res) => res.json())
                .then((doc) => {
                    if(doc.message === "Unauthorized") {
                        localStorage.removeItem("token");
                        localStorage.removeItem("session");
                        window.location.replace("/auth/login");    
                    } 

                    setList({ list: doc });
                })
                .catch((err) => alert(err));
        }
    }, [radio]);

    const handleInputChange = (e) => {
        e.preventDefault();
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleSubmit = () => {
        console.log(data);
        const requestOptions = {
            credentials: "include",
            mode: "cors",
            method: "POST",
            headers: { 
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("session")
            },
            body: JSON.stringify(data),
        };

        fetch("https://www.reewards.club/api/v1/giftcardredeemfetch", requestOptions)
            .then((res) => res.json())
            .then((doc) => {
                if (doc.data) {
                    if(doc.message === "Unauthorized") {
                        localStorage.removeItem("token");
                        localStorage.removeItem("session");
                        window.location.replace("/auth/login");    
                    } 
                    
                    setList({ list: doc });
                } else {
                    alert("Value not found");
                }
            })
            .catch((err) => {
                alert(err);
            });
    };

    const BlueRadio = withStyles({
        root: {
            color: blue[400],
            "&$checked": {
                color: blue[600],
            },
        },
        checked: {},
    })((props) => <Radio color="default" {...props} />);

    const handleChange = (event) => {
        setRadio(event.target.value);
    };

    const columns = [
        { id: "uid", label: "User ID" },
          { id: "refercode", label: "Refer Code" },
          { id: "email", label: "Email", align: "right" },
          { id: "amount", label: "Amount", align: "right" },
          { id: "totalotheroffer", label: "Other Offer", align: "right" },
          { id: "totalredeem", label: "Redeem", align: "right" },
          { id: "totalsurvey", label: "Survey", align: "right" },
          { id: "totaltaskoffer", label: "Task Offer", align: "right" },
          { id: "date", label: "Date" },
         
      ];
  
      function createData(l) {
          const listedObj = Object.values(l);
  
          const rowData = Object.values(listedObj).map((row) =>
              Object.entries(row.data).map((key, val) => {
                  return key[1];
              })
          );
          return rowData;
      }
  
      const rows = createData(list);
  
      let rowslength;
      rows.forEach((val) => {
          rowslength = val.length;
      });
  
      const [checked, setChecked] = React.useState(false);
      const [checklist, setChecklist] = React.useState({
          selected: [],
      });
  
      const handleCheck = (e) => {
          setChecked({ ...checked, [e.target.name]: e.target.checked });
          arr.push(e.target.name);
          setChecklist({ checklist: arr });
      };
  
      // console.log(checklist);
  
      const submitDelete = () => {
          console.log(checklist);
      };
  
      return (
          <>
              <UserHeader />
              <Container
                  maxWidth={false}
                  component={Box}
                  marginTop="-24rem"
                  classes={{ root: classes.containerRoot }}
              >
                  <Card classes={{ root: classes.cardRoot }}>
                      <CardHeader
                          className={classes.cardHeader}
                          title="GiftCard History"
                          titleTypographyProps={{
                              component: Box,
                              marginBottom: "0!important",
                              variant: "h3",
                          }}
                      ></CardHeader>
                      <Grid container xs={12}>
                          <Grid
                              container
                              xs={8}
                              direction="row"
                              justifyContent="flex-end"
                              alignItems="center"
                          >
                              <div>
                                  <FormControlLabel
                                      value="none"
                                      control={
                                          <BlueRadio
                                              checked={radio === "none"}
                                              onChange={handleChange}
                                          />
                                      }
                                      label="None"
                                  />
  
                                  <FormControlLabel
                                      value="refercode"
                                      control={
                                          <BlueRadio
                                              checked={radio === "refercode"}
                                              onChange={handleChange}
                                          />
                                      }
                                      label="Refer Code"
                                  />
                                  <FormControlLabel
                                      value="email"
                                      control={
                                          <BlueRadio
                                              checked={radio === "email"}
                                              onChange={handleChange}
                                          />
                                      }
                                      label="Email"
                                  />
                              </div>
                          </Grid>
                          <Grid
                              container
                              xs={4}
                              justifyContent="flex-end"
                              alignItems="flex-end"
                          >
                              <Box
                                  justifyContent="flex-end"
                                  display="flex"
                                  flexWrap="wrap"
                              >
                                  <form
                                      className={classes.textfieldContainer}
                                      noValidate
                                  >
                                      <TextField
                                          id={radio}
                                          name={radio}
                                          label="Search"
                                          type="text"
                                          variant="outlined"
                                          margin="10px"
                                          value={
                                              radio === "refercode"
                                                  ? data.refercode
                                                  : data.email
                                          }
                                          onChange={handleInputChange}
                                          className={classes.textField}
                                      />
                                      <Button
                                          variant="contained"
                                          color="info"
                                          margin="1rem!important"
                                          onClick={handleSubmit}
                                          classes={classes.buttonRootUnselected}
                                      >
                                          Submit
                                      </Button>
                                  </form>
                              </Box>
                          </Grid>
                      </Grid>
                      <TableContainer>
                          <Box
                              component={Table}
                              alignItems="center"
                              marginBottom="0!important"
                          >
                              <TableHead>
                                  <TableRow>
                                      {columns.map((column) => (
                                          <>
                                              <TableCell
                                                  key={column.id}
                                                  align={column.align}
                                                  style={{
                                                      minWidth: column.minWidth,
                                                  }}
                                              >
                                                  {column.label}
                                              </TableCell>
                                          </>
                                      ))}
                                     
                                  </TableRow>
                              </TableHead>
                              <TableBody>
                                  {rows.map((val) =>
                                      val
                                          .reverse()
                                          .slice(
                                              page * rowsPerPage,
                                              page * rowsPerPage + rowsPerPage
                                          )
                                          .map((row) => (
                                              <>
                                                  <TableRow
                                                      hover
                                                      key={row.refercode}
                                                  >
                                                     <TableCell>
                                                          {row.uid}
                                                      </TableCell>
                                                   
                                                      <TableCell>
                                                          {row.refercode}
                                                      </TableCell>
                                                      <TableCell align="right">
                                                          {row.email}
                                                      </TableCell>
                                                      <TableCell align="right">
                                                      ₹{row.amount}
                                                      </TableCell>
                                                      <TableCell align="right">
                                                          {row.totalotheroffer}
                                                      </TableCell>
                                                      <TableCell align="right">
                                                          {row.totalredeem}
                                                      </TableCell>
                                                      <TableCell align="right">
                                                          {row.totalsurvey}
                                                      </TableCell>
                                                      <TableCell align="right">
                                                          {row.totaltaskoffer}
                                                      </TableCell>
                                                      <TableCell>
                                                          {row.date}
                                                      </TableCell>
                                                     
                                                      
                                                  </TableRow>
                                              </>
                                          ))
                                  )}
                              </TableBody>
                          </Box>
                      </TableContainer>
                      <Box
                          classes={{ root: classes.cardActionsRoot }}
                          component={CardActions}
                          justifyContent="flex-end"
                      >
                          {rows.length ? (
                              <TablePagination
                                  rowsPerPageOptions={false}
                                  component="div"
                                  count={rowslength}
                                  backgroundColor="primary"
                                  variant="outlined"
                                  rowsPerPage={rowsPerPage}
                                  page={page}
                                  onPageChange={handleChangePage}
                                  onRowsPerPageChange={handleChangeRowsPerPage}
                              />
                          ) : null}
                      </Box>
                  </Card>
              </Container>
          </>
      );
  };
  
  export default Search;
  