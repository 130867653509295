import React from "react";

// @material-ui/core components
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";
import TablePagination from "@material-ui/core/TablePagination";
import Checkbox from "@material-ui/core/Checkbox";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
// core components
import UserHeader from "../../components/Headers/UserHeader.js";

import componentStyles from "../../assets/theme/views/admin/tables.js";
import { CardActionArea, IconButton, Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import axios from "axios";

const useStyles = makeStyles(componentStyles);

export default function RealtimeAnalytics() {

    const history = useHistory();
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(12);
    const [searchText, setSearchText] = React.useState("");
    const [loaded, setLoaded] = React.useState(false);
    const [data, setData] = React.useState({
        payment: 0,
        totalclicks: 0,
        totalconversion: 0,
        totalcr: 0,
        totalpayout: 0,
        totalrefercost: 0,
        totalrevenue: 0,
        newuser: 0
    });

    const [date, setDate] = React.useState({
        startDate: `${new Date().getFullYear()}-${
            new Date().getMonth() + 1
        }-${new Date().getDate()}`,
        endDate: `${new Date().getFullYear()}-${
            new Date().getMonth() + 1
        }-${new Date().getDate()}`,
    });

    React.useEffect(() => {
        fetchData();
    }, []);

    function fetchData() {

        const requestOptions = {
            headers: { 
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("session")
            }
        };

        axios   
            .post("https://www.reewards.club/api/v1/realtimeanalytics/", date, requestOptions)
            .then((response) => {
                if(response.message === "Unauthorized") {
                    localStorage.removeItem("token");
                    localStorage.removeItem("session");
                    window.location.replace("/auth/login");    
                } 

                console.log(response.data.data);
                if(response.data.data.length === 1) {
                    setData({
                        payment: response.data.data[0].payment,
                        totalclicks: response.data.data[0].totalclicks,
                        totalconversion: response.data.data[0].totalconversion,
                        totalcr: response.data.data[0].totalcr,
                        totalpayout: response.data.data[0].totalpayout,
                        totalrefercost: response.data.data[0].totalrefercost,
                        totalrevenue: response.data.data[0].totalrevenue,
                        newuser: response.data.data[0].newuser
                    });
                } else {
                    let paymentSum = 0;
                    let totalclicksSum = 0;
                    let totalconversionSum = 0;
                    let totalcrSum = 0;
                    let totalpayoutSum = 0;
                    let totalrefercostSum = 0;
                    let totalrevenueSum = 0;
                    let newuserSum = 0;
                    
                    response.data.data.map((row) => {
                        paymentSum += row.payment;
                        totalclicksSum += row.totalclicks;
                        totalconversionSum += row.totalconversion;
                        totalcrSum += parseFloat(row.totalcr);
                        totalpayoutSum += row.totalpayout;
                        totalrefercostSum += row.totalrefercost;
                        totalrevenueSum += row.totalrevenue;
                        newuserSum += row.newuser;
                    })

                    setData({
                        payment: paymentSum,
                        totalclicks: totalclicksSum,
                        totalconversion: totalconversionSum,
                        totalcr: totalcrSum/response.data.data.length,
                        totalpayout: totalpayoutSum,
                        totalrefercost: totalrefercostSum,
                        totalrevenue: totalrevenueSum,
                        newuser: newuserSum
                    });
                }
            })
            .catch((err) => alert(err));
    }

    function handleSubmit() {
        fetchData();
    }

    function handleInputChange(e) {
        setDate({
            ...date,
            [e.target.name]: e.target.value,
        });
    }

    function handleChangePage() {

    }

    function handleChangeRowsPerPage() {

    }


    return (
        <>
            <UserHeader />
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-22rem"
                classes={{ root: classes.containerRoot }}
            >
                <Card
                    classes={{ root: classes.cardRoot }}
                    style={{ backgroundColor: "transparent" }}
                >
                    <CardHeader
                        className={classes.cardHeader}
                        title="Filter"
                        titleTypographyProps={{
                            component: Box,
                            marginBottom: "0!important",
                            variant: "h3",
                        }}
                        style={{
                            marginBottom: "5rem",
                            backgroundColor: "white",
                        }}
                        action={
                            <Box
                                justifyContent="flex-end"
                                display="flex"
                                flexWrap="wrap"
                            >
                                <form
                                    className={classes.textfieldContainer}
                                    noValidate
                                >
                                    <TextField
                                        id="taskId"
                                        name="taskId"
                                        label="Search"
                                        type="text"
                                        variant="outlined"
                                        margin="10px"
                                        value={searchText}
                                        onChange={(e) =>
                                            setSearchText(e.target.value)
                                        }
                                        className={classes.textField}
                                    />
                                    <TextField
                                        id="startDate"
                                        name="startDate"
                                        label="Start Date"
                                        defaultValue="24-05-2021"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        type="date"
                                        variant="outlined"
                                        margin="10px"
                                        value={date.startDate}
                                        onChange={handleInputChange}
                                        className={classes.textField}
                                    />
                                    <TextField
                                        id="endDate"
                                        name="endDate"
                                        label="End Date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        type="date"
                                        variant="outlined"
                                        margin="10px"
                                        value={date.endDate}
                                        onChange={handleInputChange}
                                        className={classes.textField}
                                    />
                                    <Button
                                        variant="contained"
                                        color="info"
                                        margin="1rem!important"
                                        onClick={() => handleSubmit()}
                                        classes={classes.buttonRootUnselected}
                                    >
                                        Submit
                                    </Button>
                                </form>
                            </Box>
                        }
                    ></CardHeader>
                    <Grid container xs={12}>
                        <Grid
                            container
                            xs={12}
                            justifyContent="flex-end"
                            alignItems="flex-end"
                        ></Grid>
                    </Grid>

                    {/* Cards */}
                    <Grid container spacing={6} style={{ padding: "1rem" }}>
                        
                                <Grid item xs={3} style={{ marginTop: "2rem" }}>
                                    <Paper
                                        elevation={5}
                                        style={{ padding: "2rem" }}
                                        >
                                        <Grid 
                                            container
                                            style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}
                                            >
                                            <Typography variant="h1">
                                                <b>{data.newuser}</b>
                                            </Typography>

                                            <Typography variant="h4" style={{opacity: '0.6'}}>
                                                Users
                                            </Typography>

                                            
                                        </Grid>
                                    </Paper>
                                </Grid>

                                <Grid item xs={3} style={{ marginTop: "2rem" }}>
                                    <Paper
                                        elevation={5}
                                        style={{ padding: "2rem" }}
                                        >
                                        <Grid 
                                            container
                                            style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}
                                            >
                                            <Typography variant="h1">
                                                <b>{data.totalclicks}</b>
                                            </Typography>

                                            <Typography variant="h3" style={{opacity: '0.6'}}>
                                                Clicks
                                            </Typography>

                                          
                                        </Grid>
                                    </Paper>
                                </Grid>

                                <Grid item xs={3} style={{ marginTop: "2rem" }}>
                                    <Paper
                                        elevation={5}
                                        style={{ padding: "2rem" }}
                                        >
                                        <Grid 
                                            container
                                            style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}
                                            >
                                            <Typography variant="h1">
                                                <b>{data.totalconversion}</b>
                                            </Typography>

                                            <Typography variant="h3" style={{opacity: '0.6'}}>
                                                Conversion
                                            </Typography>

                                            
                                        </Grid>
                                    </Paper>
                                </Grid>

                                <Grid item xs={3} style={{ marginTop: "2rem" }}>
                                    <Paper
                                        elevation={5}
                                        style={{ padding: "2rem" }}
                                        >
                                        <Grid 
                                            container
                                            style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}
                                            >
                                            <Typography variant="h1">
                                                <b>{(data.totalconversion/data.totalclicks*100).toFixed(1)}%</b>
                                            </Typography>

                                            <Typography variant="h3" style={{opacity: '0.6'}}>
                                                CR
                                            </Typography>

                                            
                                        </Grid>
                                    </Paper>
                                </Grid>

                                <Grid item xs={3} style={{ marginTop: "2rem" }}>
                                    <Paper
                                        elevation={5}
                                        style={{ padding: "2rem" }}
                                        >
                                        <Grid 
                                            container
                                            style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}
                                            >
                                            <Typography variant="h1">
                                                <b>₹{data.totalpayout}</b>
                                            </Typography>

                                            <Typography variant="h3" style={{opacity: '0.6'}}>
                                                Payout
                                            </Typography>

                                            
                                        </Grid>
                                    </Paper>
                                </Grid>

                                

                                <Grid item xs={3} style={{ marginTop: "2rem" }}>
                                    <Paper
                                        elevation={5}
                                        style={{ padding: "2rem" }}
                                        >
                                        <Grid 
                                            container
                                            style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}
                                            >
                                            <Typography variant="h1">
                                                <b>₹{data.totalrevenue}</b>
                                            </Typography>

                                            <Typography variant="h3" style={{opacity: '0.6'}}>
                                                Revenue
                                            </Typography>

                                           
                                        </Grid>
                                    </Paper>
                                </Grid>

                                <Grid item xs={3} style={{ marginTop: "2rem" }}>
                                    <Paper
                                        elevation={5}
                                        style={{ padding: "2rem" }}
                                        >
                                        <Grid 
                                            container
                                            style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}
                                            >
                                            <Typography variant="h1">
                                                <b>₹{data.payment}</b>
                                            </Typography>

                                            <Typography variant="h3" style={{opacity: '0.6'}}>
                                                Payment
                                            </Typography>

                                           
                                        </Grid>
                                    </Paper>
                                </Grid>
                                <Grid item xs={3} style={{ marginTop: "2rem" }}>
                                    <Paper
                                        elevation={5}
                                        style={{ padding: "2rem" }}
                                        >
                                        <Grid 
                                            container
                                            style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}
                                            >
                                            <Typography variant="h1">
                                                <b>{(data.totalrevenue/data.newuser).toFixed(1)}</b>
                                            </Typography>

                                            <Typography variant="h3" style={{opacity: '0.6'}}>
                                                RPU
                                            </Typography>

                                        </Grid>
                                    </Paper>
                                </Grid>

                                <Grid item xs={3} style={{ marginTop: "2rem" }}>
                                    <Paper
                                        elevation={5}
                                        style={{ padding: "2rem" }}
                                        >
                                        <Grid 
                                            container
                                            style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}
                                            >
                                            <Typography variant="h1">
                                                <b>{(data.payment/data.newuser).toFixed(1)}</b>
                                            </Typography>

                                            <Typography variant="h3" style={{opacity: '0.6'}}>
                                                PPU
                                            </Typography>

                                        </Grid>
                                    </Paper>
                                </Grid>

                                <Grid item xs={3} style={{ marginTop: "2rem" }}>
                                    <Paper
                                        elevation={5}
                                        style={{ padding: "2rem" }}
                                        >
                                        <Grid 
                                            container
                                            style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}
                                            >
                                            <Typography variant="h1">
                                                <b>{(data.totalrevenue/data.totalconversion).toFixed(1)}</b>
                                            </Typography>

                                            <Typography variant="h3" style={{opacity: '0.6'}}>
                                                RPL
                                            </Typography>

                                        </Grid>
                                    </Paper>
                                </Grid>

                                <Grid item xs={3} style={{ marginTop: "2rem" }}>
                                    <Paper
                                        elevation={5}
                                        style={{ padding: "2rem" }}
                                        >
                                        <Grid 
                                            container
                                            style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}
                                            >
                                            <Typography variant="h1">
                                                <b>{(data.totalconversion/data.newuser).toFixed(1)}</b>
                                            </Typography>

                                            <Typography variant="h3" style={{opacity: '0.6'}}>
                                                LBU
                                            </Typography>

                                        </Grid>
                                    </Paper>
                                </Grid>

                                

                    </Grid>

                </Card>
            </Container>
        </>
    );
}
