import firebase from 'firebase/compat/app';

const firebaseConfig = {
  apiKey: "AIzaSyAsPVGHHsSFj27y5U1IbEK0MgDONE_oesI",
  authDomain: "reewards-24c84.firebaseapp.com",
  databaseURL: "https://reewards-24c84-default-rtdb.firebaseio.com",
  projectId: "reewards-24c84",
  storageBucket: "reewards-24c84.appspot.com",
  messagingSenderId: "310587028018",
  appId: "1:310587028018:web:7d0d94900af7a26032822b",
  measurementId: "G-CMYY4TTMJF"
};

firebase.initializeApp(firebaseConfig);