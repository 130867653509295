import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
// @material-ui/icons components
import GroupAdd from "@material-ui/icons/GroupAdd";
import PaymentSharpIcon from "@material-ui/icons/PaymentSharp";
import TouchAppSharpIcon from "@material-ui/icons/TouchAppSharp";
import TrendingUpSharpIcon from "@material-ui/icons/TrendingUpSharp";
import ReceiptSharpIcon from "@material-ui/icons/ReceiptSharp";
import AttachMoneySharpIcon from "@material-ui/icons/AttachMoneySharp";
// core components
import CardStats from "../Cards/CardStats.js";
import axios from 'axios';
import componentStyles from "../../assets/theme/components/header.js";
import { color } from "@mui/system";

const useStyles = makeStyles(componentStyles);

const Header = (props) => {
    const { rows, isFiltered, showAll } = props;
    const classes = useStyles();
    const [date, setDate] = React.useState({
        startDate: `${new Date().getFullYear()}-${
            new Date().getMonth() + 1
        }-${new Date().getDate()}`,
        endDate: `${new Date().getFullYear()}-${
            new Date().getMonth() + 1
        }-${new Date().getDate()}`,
    });
    
    const [val, setVal] = React.useState({
        payment: 0,
        totalclicks: 0,
        totalconversion: 0,
        totalcr: 0,
        totalpayout: 0,
        totalrevenue: 0,
        totaluser: 0,
        totalrefercost:0,
    });
    const [valnew, setValnew] = React.useState({
    
        paytmbalance: 0
    });
    const [derivedVal, setDerivedVal] = React.useState({
        payment: 0,
        totalclicks: 0,
        totalconversion: 0,
        totalcr: 0,
        totalpayout: 0,
        totalrevenue: 0,

    });
    const [todayVal, setTodayVal] = React.useState({
        payment: 0,
        totalclicks: 0,
        totalconversion: 0,
        totalcr: 0,
        totalpayout: 0,
        totalrevenue: 0,
    });

    const fetchData = async () => {
        const requestOptions = {
            credentials: "include",
            headers: {
                "Authorization": localStorage.getItem("session")
            }
        };
        return fetch("https://reewards.club/api/v1/analytics", requestOptions)
            .then((res) => res.json())
            .then((data) => {
                if(data.message === "Unauthorized") {
                    localStorage.removeItem("token");
                    localStorage.removeItem("session");
                    window.location.replace("/auth/login");    
                } 

                setVal({
                    payment: data.data.payment,
                    totalclicks: data.data.totalclicks,
                    totalconversion: data.data.totalconversion,
                    totalcr: data.data.totalcr,
                    totalpayout: data.data.totalpayout,
                    totalrevenue: data.data.totalrevenue,
                    totaluser: data.data.totaluser,
                    totalrefercost: data.data.totalrefercost
                    
                })
                
            })

            .catch((err) => console.error(err));
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    React.useEffect(() => {
        const requestOptions = {
            credentials: "include",
            mode: "cors",
            method: "POST",
            headers: { 
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("session")
            },
            body: JSON.stringify(date),
        };

        fetch("https://reewards.club/api/v1/filteranalytics", requestOptions)
            .then((res) => res.json())
            .then((res) => {
                // console.log(res);
                return res;
            })
            .then((data) =>
                setTodayVal({
                    payment: Object.values(data.data)[0].payment,
                    totalclicks: Object.values(data.data)[0].totalclicks,
                    totalconversion: Object.values(data.data)[0].totalconversion,
                    totalcr: Object.values(data.data)[0].totalcr,
                    totalpayout: Object.values(data.data)[0].totalpayout,
                    totalrevenue: Object.values(data.data)[0].totalrevenue,
                    totaluser: Object.values(data.data)[0].totaluser,
                    totalrefercost: Object.values(data.data)[0].totalrefercost,

                })
            )
            .catch((err) => console.error(err));
    }, []);

    React.useEffect(() => {
        const requestOptions = {
            credentials: "include",
            mode: "cors",
            method: "POST",
            headers: { 
                "Content-Type": "application/json",
            },
        };

        fetch("https://reewards.club/api/v1/paytmbalance/", requestOptions)
           .then((res) => res.json())
           .then((res) => {
            console.log(res.data);
            setValnew({
                paytmbalance: res.data
               
            })
        })
          
            
            
    

        .catch((err) => console.error(err));

            
           
            
    }, []);


    // square card data
    // React.useEffect(() => {
    //     const requestOptions = {
    //         headers: {
    //             "Authorization": localStorage.getItem("session")
    //         }
    //     }

    //     axios
    //         .get("", requestOptions)
    //         .then(response => {

    //         }) 
    //         .catch(err => {
    //             console.error(err)
    //         })
    // })


    React.useEffect(() => {
        // console.log(rows);
        let paymentSum = 0;
        let totalclicksSum = 0;
        let totalconversionSum = 0;
        let totalcrSum = 0;
        let totalpayoutSum = 0;
        let totalrevenueSum = 0;
        if (rows[0]) {
            rows[0].map((row) => {
                if (row[1][0] !== null) {
                    paymentSum += row[1].payment;
                    totalclicksSum += row[1].totalclicks;
                    totalconversionSum += row[1].totalconversion;
                    totalcrSum += parseFloat(row[1].totalcr);
                    totalpayoutSum += row[1].totalpayout;
                    totalrevenueSum += row[1].totalrevenue;
                }
            });
            setDerivedVal({
                payment: paymentSum,
                totalclicks: totalclicksSum,
                totalconversion: totalconversionSum,
                totalcr: totalcrSum / rows[0].length,
                totalpayout: totalpayoutSum,
                totalrevenue: totalrevenueSum,
            });
        }
    }, [rows]);

    // const cardEntries = [
    //     { subtitle: "Subtitle", title: "Title" },
    //     { subtitle: "NewCard", title: "112233" },
    //     { subtitle: "Card3", title: "Data3" },
    // ];

    // const handleUpdate = (propertyName) => (newValue) => {
    //     let entry = cardEntries.find(
    //         (entry) => entry.subtitle === propertyName
    //     );
    //     if (entry.title !== newValue) {
    //         console.log(propertyName + " updated to " + newValue);
    //     } else {
    //         console.log("No update");
    //     }
    // };

    return (
        <>
            <div className={classes.header}>
                <Container
                    maxWidth={false}
                    component={Box}
                    classes={{ root: classes.containerRoot }}
                >
                    <div>
                        <Grid
                            container
                            style={{ marginLeft: "12rem!important" }}
                        >
                            <Grid item xl={4} lg={6} xs={12}>
                                <CardStats
                                    subtitle="Revenue"
                                    title={
                                        <>
                                            <span>&#8377;</span>
                                            {isFiltered
                                                ? derivedVal.totalrevenue.toLocaleString()
                                                : showAll
                                                ? val.totalrevenue.toLocaleString()
                                                : todayVal.totalrevenue.toLocaleString()}
                                        </>
                                    }
                                    icon={AttachMoneySharpIcon}
                                    color="bgPrimary"
                                />
                            </Grid>
                            <Grid item xl={4} lg={6} xs={12}>
                                <CardStats
                                    subtitle="Payment"
                                    title={
                                        <>
                                            <span>&#8377;</span>
                                            {isFiltered
                                                ? derivedVal.payment.toLocaleString()
                                                : showAll
                                                ? val.payment.toLocaleString()
                                                : todayVal.payment.toLocaleString()}
                                        </>
                                    }
                                    icon={PaymentSharpIcon}
                                    color="bgError"
                                />
                            </Grid>
                            <Grid item xl={4} lg={6} xs={12}>
                                <CardStats
                                    subtitle="Conversion"
                                    title={
                                        isFiltered
                                            ? derivedVal.totalconversion.toLocaleString()
                                            : showAll
                                            ? val.totalconversion.toLocaleString()
                                            : todayVal.totalconversion.toLocaleString()
                                    }
                                    icon={TrendingUpSharpIcon}
                                    color="bgWarningLight"
                                />
                            </Grid>
                         
                            
                            
                            <Grid item xl={4} lg={6} xs={12}>
                                <CardStats
                                    subtitle="Payout"
                                    title={
                                        <>
                                            <span>&#8377;</span>
                                            {isFiltered
                                                ? derivedVal.totalpayout.toLocaleString()
                                                : showAll
                                                ? val.totalpayout.toLocaleString()
                                                : todayVal.totalpayout.toLocaleString()}
                                        </>
                                    }
                                    icon={AttachMoneySharpIcon}
                                    color="bgInfo"
                                />
                            </Grid>
                         

                            <Grid item xl={4} lg={6} xs={12}>
                                <CardStats
                                    subtitle="Clicks"
                                    title={
                                        isFiltered
                                            ? derivedVal.totalclicks.toLocaleString()
                                            : showAll
                                            ? val.totalclicks.toLocaleString()
                                            : todayVal.totalclicks.toLocaleString()
                                    }
                                    icon={TouchAppSharpIcon}
                                    color="bgWarning"
                                />
                            </Grid>
                            <Grid item xl={4} lg={6} xs={12}>
                                <CardStats
                                    subtitle="Cr"
                                    title={`${
                                        isFiltered
                                            ? derivedVal.totalcr.toLocaleString()
                                            : showAll
                                            ? val.totalcr.toLocaleString()
                                            : todayVal.totalcr.toLocaleString()
                                    }%`}
                                    icon={ReceiptSharpIcon}
                                    color="bgInfoLight"
                                />
                            </Grid>
                            <Grid item xl={4} lg={6} xs={12}>
                                <CardStats
                                    subtitle="Refer Spend"
                                    title={
                                        <>
                                        
                                        <span>&#8377;</span>
                                            {val.totalrefercost.toLocaleString()}
                                        </>
                                    }
                                    icon={GroupAdd}
                                    color="bgWarningLight"
                                />
                            </Grid>
                            <Grid item xl={4} lg={6} xs={12}>
                                <CardStats
                                    subtitle="Users"
                                    title={
                                        <>
                                            <span></span>
                                            {val.totaluser.toLocaleString()}
                                        </>
                                    }
                                    icon={GroupAdd}
                                    color="bgPrimary"
                                />
                            </Grid>
                          
                            <Grid item xl={4} lg={6} xs={12}>
                                <CardStats
                                    subtitle="Paytm Payout Balance"
                                    
                                    title={
                                        <>
                                        
                                        <span>&#8377;</span>
                                            {valnew.paytmbalance}
                                        </>
                                        
                                    }

                                    icon={AttachMoneySharpIcon}
                                    color="bgError"
                                   
                                />
                            </Grid>
                            {/* {cardEntries.map((entry) => (
                                <Grid
                                    item
                                    xl={4}
                                    lg={6}
                                    xs={12}
                                    key={entry.subtitle}
                                >
                                    <CardStats
                                        subtitle={entry.subtitle}
                                        title={
                                            <>
                                                <span></span>
                                                {entry.title}
                                            </>
                                        }
                                        icon={GroupAdd}
                                        color="bgWarning"
                                        editable
                                        updateFun={handleUpdate(entry.subtitle)}
                                        defaultVal={entry.title}
                                    />
                                </Grid>
                            ))} */}
                        </Grid>
                
                    {/* <Grid
                        container 
                        wrap='wrap'
                        spacing={3}
                        style={{marginTop: '3vh', marginBottom: '2vh'}}
                    >
                        <Grid item>
                            <Box
                                sx={{
                                    width: 200,
                                    height: 200,
                                    backgroundColor: 'white'
                                }}
                                borderRadius={10}
                                boxShadow={4}
                                style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}
                            >
                                <b>CLICKS</b>
                                123
                            </Box>
                        </Grid>

                        <Grid item>
                            <Box
                                sx={{
                                    width: 200,
                                    height: 200,
                                    backgroundColor: 'white'
                                }}
                                borderRadius={10}
                                boxShadow={4}
                            />
                        </Grid>

                        <Grid item>
                            <Box
                                sx={{
                                    width: 200,
                                    height: 200,
                                    backgroundColor: 'white'
                                }}
                                borderRadius={10}
                                boxShadow={4}
                            />
                        </Grid>

                        <Grid item>
                            <Box
                                sx={{
                                    width: 200,
                                    height: 200,
                                    backgroundColor: 'white'
                                }}
                                borderRadius={10}
                                boxShadow={4}
                            />
                        </Grid>

                        <Grid item >
                            <Box
                                sx={{
                                    width: 200,
                                    height: 200,
                                    backgroundColor: 'white'
                                }}
                                borderRadius={10}
                                boxShadow={4}
                            />
                        </Grid>

                        <Grid item>
                            <Box
                                sx={{
                                    width: 200,
                                    height: 200,
                                    backgroundColor: 'white'
                                }}
                                borderRadius={10}
                                boxShadow={4}
                            />
                        </Grid>

                        <Grid item>
                            <Box
                                sx={{
                                    width: 200,
                                    height: 200,
                                    backgroundColor: 'white'
                                }}
                                borderRadius={10}
                                boxShadow={4}
                            />
                        </Grid>

                        <Grid item >
                            <Box
                                sx={{
                                    width: 200,
                                    height: 200,
                                    backgroundColor: 'white'
                                }}
                                boxShadow={4}
                                borderRadius={10}
                            />
                        </Grid>

                        <Grid item>
                            <Box
                                sx={{
                                    width: 200,
                                    height: 200,
                                    backgroundColor: 'white'
                                }}
                                boxShadow={4}
                                borderRadius={10}
                            />
                        </Grid>
                        
                    </Grid> */}

                    </div>
                </Container>
            </div>
        </>
    );
};

export default Header;
